.side__container {
    display: flex;
    flex-direction: column;
    width: 300px;
    background: #FFFFFF;
}

.logo__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    margin-bottom: 0px;
    background-color: white;
}

.header__container {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: fixed;
    z-index: 999;
}

.header-menu {
    border: none;
    background: none;
    margin-right: 24px;
}

.menu {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    position: absolute;
    z-index: 1;
    right: 24px;
    top: 90px;
    border-radius: 10px;
    border: 1px solid #909090;
    overflow: hidden;
}

.header-menu-button {
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: center;
    width: 170px;
    text-decoration: none;
    padding: 12px;
    color: #2F3A4A;
    font-size: 17px;
    font-weight: 400;
}

@media(max-width: 1065px) {
    .side__container {
        width: 220px;
    }
}