@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

@font-face {
  font-family: raleway_semibold;
  src: url("./fonts/Raleway-SemiBold.ttf");
}

@font-face {
  font-family: raleway_bold;
  src: url("./fonts/Raleway-Bold.ttf");
}

@font-face {
  font-family: raleway_medium;
  src: url("./fonts/Raleway-Medium.ttf");
}

@font-face {
  font-family: lato_bold;
  src: url("./fonts/Lato-Bold.ttf");
}

@font-face {
  font-family: lato_regular;
  src: url("./fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: tinos_regular;
  src: url("./fonts/Tinos-Regular.ttf");
}

@font-face {
  font-family: verdana_;
  src: url("./fonts/Verdana.ttf");
}

@font-face {
  font-family: garamond_;
  src: url("./fonts/EBGaramond-VariableFont_wght.ttf");
}

@font-face {
  font-family: cambria_;
  src: url("./fonts/Cambria\ Lite_.ttf");
}

@font-face {
  font-family: open_sans;
  src: url("./fonts/OpenSans_Condensed-Regular.ttf");
}

@font-face {
  font-family: arial_;
  src: url("./fonts/ARIAL.TTF");
}

@font-face {
  font-family: calibri_;
  src: url("./fonts/calibril.ttf");
}

body,
html {
  overflow: auto;
}

#root {
  overflow: auto;
}

.pagination {
  display: flex;
  margin: 0px;
}

.break-label {
  display: flex;
  margin-left: 12px;
}

.break-label-link {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
  color: #404B52;
  text-decoration: none;
}

.page-number {
  list-style-type: none;
  margin-left: 12px;
}

.active-page {
  background: #3888FF !important;
  color: #FFFFFF !important;
  border-radius: 4px;
}

.page-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
  background: #F5F6FA;
  border-radius: 4px;
  font-family: 'Lexend';
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #404B52;
  border: none;
}

.carousel-container {
  margin-left: 0.7vw;
  width: 63vw;
  z-index: 2;
}

.react-calendar {
  border: none !important;
}

.carousel .control-dots .dot {
  width: 6px !important;
  height: 6px !important;
  margin: 0px 4px !important;
}

.span-style {
  font-family: 'Lexend';
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #383838;
  white-space: nowrap;
}

.wrapper {
  width: 100% !important;
  display: block !important;
  margin-bottom: 0px !important;
}

.ql-toolbar {
  margin-top: 24px;
}

.toolbar {
  border: none !important;
  position: relative !important;
}

.rdw-editor-toolbar {
  padding: 6px 0px 0px 0px !important;
}

.public-DraftStyleDefault-block {
  margin: 3px !important;
}

.rdw-emoji-wrapper {
  display: none !important;
}

.rdw-colorpicker-wrapper {
  display: none !important;
}

.rdw-dropdown-selectedtext {
  text-decoration: none;
  color: black;
}

.loader {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh !important;
}

#phone {
  box-sizing: border-box !important;
  background: #F5F6FA !important;
  min-width: 100% !important;
  height: 50px !important;
  border-radius: 4px !important;
  border: none !important;
  font-family: 'Lexend' !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #292D32 !important;
  padding-left: 15px !important;
  margin: 10px 0px 10px 0px !important;
}

.react-tel-input .special-label {
  display: none;
}

.react-tel-input .flag-dropdown {
  display: none;
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 6px 6px;
  background: none;
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 0.833em;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
  color: #ababab;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
  color: #cdcdcd;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: rgb(98, 197, 132);
  color: white;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: rgb(98, 197, 132);
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-datepicker__input-container input {
  background-color: #f5f6fa;
  border: none;
  width: 134px;
  padding: 10px 0px 10px 30px;
  outline: 0;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
}

.inline_editor::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999;
  opacity: 1;
  /* Firefox */
}

.inline_editor::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999;
}

.inline_editor::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #999;
}

[data-placeholder]:empty:before {
  content: attr(data-placeholder);
  color: #888;
  /* font-style: italic; */
}

.ql-editor.ql-blank::before {
  font-style: unset !important;
  left: 0 !important;
}

.ql-editor {
  padding: 6px 0px !important;
}

.ql-editor p {
  font-family: Montserrat !important;
}

.ql-toolbar.ql-snow {
  position: absolute;
  top: -60px;
  background: white;
  width: 100%;
  left: 0;
  right: 0;
  box-shadow: 0 3px 6px silver;
  display: none;
  border-radius: 6px;
  z-index: 1;
  margin: 0 auto;
}

.aft {
  width: 816px;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  /* height: 1668px; */
  margin: 0 auto;
  transform: translate(0, 45px);
}

.aft::before {
  transform: translate(-100px, 0);
  background: black;
  height: 20px;
  width: 80px;
  content: "Page1";
  width: 80px;
  display: block;
  color: #fff;
}

.ql-tooltip.ql-editing,
.ql-tooltip {
  z-index: 1;
}

.ql-editor ul {
  margin-left: -9.75px !important;
  padding: 0 !important;
}

.ql-editor ol {
  padding: 0 !important;
}

.ql-editor li::before {
  position: absolute !important;
}

.ql-container.ql-snow {
  border: 0 !important
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0 !important
}

.ql-snow.ql-toolbar:after,
.ql-snow .ql-toolbar:after {
  content: unset !important;
}

.summary_result {
  max-width: 100% !important;
  padding: 10px;
  background-color: #EFF1F3;
}

.summary_insert {
  max-width: 100% !important;
  padding: 10px;
  background-color: #EFF1F3;
}

.summary_result::-webkit-scrollbar,
.tags_container::-webkit-scrollbar {
  width: 0px;
  height: 0px
}

/* Track */
.summary_result::-webkit-scrollbar-track,
.tags_container::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.summary_result::-webkit-scrollbar-thumb,
.tags_container::-webkit-scrollbar-thumb {
  background: transparent;
}

.recharts-responsive-container {
  width: auto !important
}

@media(max-width: 1382px) {
  .carousel-container {
    width: 60vw;
  }
}

@media(max-width: 1274px) {
  .carousel-container {
    width: 55vw;
  }
}

@media(max-width: 1198px) {
  .carousel-container {
    margin-left: 1vw;
  }
}

@media(max-width: 953px) {
  .carousel-container {
    width: 73vw;
    margin-left: 2.7vw;
  }
}

@media(max-width: 869px) {
  .carousel-container {
    margin-left: 1.7vw;
  }
}

@media(max-width: 820px) {
  .carousel-container {
    margin-left: 1vw;
  }
}

@media(max-width: 792px) {
  .carousel-container {
    margin-left: 2.6vw;
  }
}

@media(max-width: 707px) {
  .carousel-container {
    margin-left: 1.7vw;
  }
}

@media(max-width: 840px) {
  .page-link {
    width: 23px;
    height: 23px;
  }

  .break-label-link {
    width: 23px;
    height: 23px;
  }
}

.Toastify__toast-container {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center;
  width: fit-content !important;
}

.Toastify__toast {
  width: fit-content !important;
}